import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

class Rules extends Component {

    render() {
        return (
            <div className="content">
                <div className="main">
                    <a href="/home">
                        <img
                            alt=""
                            src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb.png"
                            className="file-head"
                        />
                    </a>

                    <Row className="justify-content-md-center">
                        <Col xs={12} md={8} lg={8}>
                            <h4 className="text-center">
                                Reglamento Interno
                            </h4>
                            <p className="text-justify">
                                REGLAMENTO INTERNO DE BODY BARRE MÉXICO, S.A. DE C.V., (BODY BARRE) PARA SUS USUARIOS.
                            </p>
                            <p className="text-justify">
                                <ol style={{listStyleType : "upper-roman"}}>
                                    <li>
                                        BODY BARRE PRESTA LOS SIGUIENTES SERVICIOS A SUS USUARIOS:
                                        <ol>
                                            <li>
                                                - CLASES DE BARRA FIJA DE ALTO RENDIMIENTO PARA MUJERES.
                                            </li>
                                            <li>
                                                - INSTALACIONES EN DIFERENTES PARTES DE LA CIUDAD, ACTUALES Y EN UN FUTURO. (Disponibles para su consulta en su sitio web https://bodybarre.com.mx, en su BODY BARRE APP, sus redes sociales y/o cualquier otro medio que BODY BARRE así determine).
                                            </li>
                                            <li>
                                                - DIFERENTES HORARIOS A ESCOGER SEGÚN SUS NECESIDADES.
                                            </li>
                                            <li>
                                                - SUPERVISIÓN Y ASESORAMIENTO DE EJERCICIOS FÍSICOS.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </p>
                            
                            <p className="text-justify">
                                NUESTRA “BODY BARRE App”, FORMA DE UTILIZAR PARA SU MÁXIMO APROVECHAMIENTO
                            </p>

                            <p className="text-justify">
                                Es importante leer con cuidado las instrucciones para tener un eficiente servicio de nuestro sitio. Toda la información que usted plasme en nuestro sitio será validada en cualquier momento por BODY BARRE MÉXICO, S.A DE C.V. y será responsabilidad del usuario la veracidad de la misma.<br/>
                                
                                Para poder disfrutar de los servicios proporcionados por BODY BARRE, el usuario deberá realizar los siguientes procesos: <br/>
                                
                                Darse de alta en nuestra “BODY BARRE App” y será necesario seguir el procedimiento que se mencionará al ingresar a nuestro sitio, donde obtendrá una cuenta con la cual podrá tener acceso a toda la información necesaria para el mejor uso de nuestras instalaciones.<br/>
                                
                                El usuario acepta proporcionar la información que se le requiera teniendo en consideración lo dispuesto en la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES.<br/>
                                
                                El usuario acepta proporcionar los datos relativos a su forma de pago vía electrónica, para realizar los pagos correspondientes a lo contratado.<br/>
                                
                                Queda a discreción de BODY BARRE aceptar la forma de pago del usuario.<br/>
                                
                                Una vez que el usuario acepte electrónicamente los TÉRMINOS Y CONDICIONES publicados en BODY BARRE APP, se mostrará el formulario de pago correspondiente al motor de pago que BODY BARRE considere utilizar, por lo tanto, autoriza a BODY BARRE y/o al proveedor de servicios de cobro para que se realice el cargo automático a la tarjeta de débito, crédito o cualquier otro medio de pago electrónico que usted proporcionó a BODY BARRE al momento de procesar el cobro por concepto del paquete de clases que el usuario haya seleccionado previamente.<br/>
                                
                                El usuario se hará cargo de su contraseña y será el único responsable del uso que se dé de la misma.<br/>
                                
                                El usuario manifiesta y hace constar que, al usar los servicios que BODY BARRE proporciona, tanto en su modalidad presencial como mediante su BODY BARRE APP, acepta en su totalidad este Reglamento Interno junto con todas las disposiciones y definiciones descritas a continuación:<br/>

                            </p>

                            <p className="text-justify">
                                ¿CÓMO INSCRIBIRME A LAS CLASES?
                            </p>

                            <p className="text-justify">
                                <ol>
                                    <li>
                                        Al ingresar a “BODY BARRE App” y tras realizar el pago del paquete de clases seleccionado mediante su cuenta de usuario y contraseña previamente registradas, BODY BARRE le permitirá apartar un cupo dentro de las clases de “BODY BARRE App” que se encuentren disponibles en el sistema.
                                    </li>
                                    <li>
                                        Al momento en que el usuario indique qué clase desee tomar, este será registrado en la plataforma y estará en la lista de asistentes a dicha clase. 
                                    </li>
                                    <li>
                                        En caso de inasistencia a la reservación de clases presenciales hecha por el usuario, BODY BARRE describe los lineamientos a seguir dentro de sus TÉRMINOS Y CONDICIONES DEL SERVICIO publicados y aceptados electrónicamente por el usuario antes de realizar el pago correspondiente al paquete cuya clase presencial ha sido reservada.
                                    </li>
                                </ol>
                            </p>

                            <p className="text-justify">
                                RESPONSABILIDAD DEL USUARIO
                            </p>

                            <p className="text-justify">
                                <ol>
                                    <li>
                                        El uso de las instalaciones será única y exclusivamente bajo la supervisión del instructor.
                                    </li>
                                    <li>
                                        Entiende y acepta que debe acudir con ropa y calzado adecuado para realizar las actividades que BODY BARRE ofrece. 
                                    </li>
                                    <li>
                                        No maltratar el equipo ni las instalaciones.
                                    </li>
                                    <li>
                                        No fumar dentro de las instalaciones.
                                    </li>
                                    <li>
                                        No alterar el orden ni incitar a alumnas, usuarios, visitantes, personal o a cualquier otra persona dentro de las instalaciones de BODY BARRE.
                                    </li>
                                    <li>
                                        Únicamente podrá ingresar al estudio la persona que cuente con la credencial que lo acredite como alumno activo.
                                    </li>
                                    <li>
                                        Respetar el área destinada para la venta de alimentos y/o cualquier otro servicio que BODY BARRE provea dentro de sus instalaciones.
                                    </li>
                                    <li>
                                        Al alumno que resulte responsable de descompostura por mal uso del equipo, mobiliario o instalaciones se le cobraré el costo de las reparaciones o reposiciones respectivas.
                                    </li>
                                    <li>
                                        Cualquier instructor o encargado del gimnasio tiene la autoridad para hacer vale este reglamento, así como pedirle a cualquier alumno que abandone el gimnasio por no acatar estas disposiciones.
                                    </li>
                                    <li>
                                        No está permitido darle acceso a terceras personas a “BODY BARRE App” utilizando las claves de los usuarios, BODY BARRE, S.A. DE C.V. podrá en su momento cancelar las claves de los usuarios que infrinjan ésta disposición.
                                    </li>
                                    <li>
                                        Es responsabilidad del usuario utilizar la página web para los fines exclusivos que BODY BARRE señale en el presente reglamento.
                                    </li>
                                    <li>
                                        Cualquier otro uso que se le dé será responsabilidad del usuario y se atendrá a las consecuencias del malestar o daño que haya causado, que puede ser desde una amonestación verbal o escrita o si, el hecho lo amerita, ponerle a disposición de las autoridades competentes en el caso de infringir la ley.
                                    </li>
                                    <li>
                                        Toda la información que se menciona en nuestra “BODY BARRE App” es confidencial, por lo que no está permitido transferirla a terceras personas.
                                    </li>
                                    <li>
                                        El uso de nuestra “BODY BARRE App” no tiene ningún costo.
                                    </li>
                                    <li>
                                        Podrán asistir a nuestras clases presenciales las personas mayores de 16 años.
                                    </li>
                                    <li>
                                        El pago de las tarifas se realizarán por el propio interesado en la cuenta de entidad bancaria que BODY BARRE tiene considerado para tal fin.
                                    </li>
                                    <li>
                                        El pago de las cuotas establecidas se realizará antes de poder reservar un estudio, fecha, horario y lugar (mat).
                                    </li>
                                    <li>
                                        El usuario podrá disfrutar de sus clases de acuerdo a las normas de uso establecidas en la instalación.
                                    </li>
                                    <li>
                                        El usuario podrá presentar sugerencias o reclamaciones que estime convenientes en cualquier plataforma que BODY BARRE así haya determinado, ya sea en su centro de mensajes de redes sociales, WhatsApp o cualquier otro medio dispuesto por BODY BARRE.
                                    </li>
                                    <li>
                                        Velar por el buen estado de conservación de las instalaciones y servicios, impidiendo o denunciando todo acto que vaya en deterioro de las mismas y advirtiendo cuando observen anomalías en la instalación o en el material de las mismas.
                                    </li>
                                    <li>
                                        Se prohíbe comer y fumar en todo el recinto, así como utilizar envases de vidrio.
                                    </li>
                                    <li>
                                        Hacer uso de la instalación con la ropa y calzado deportivo adecuados.
                                    </li>
                                    <li>
                                    Guardar el debido respeto a los demás usuarios y al personal de la instalación, así como atender en todo momento las indicaciones del mismo, cuyo cometido es supervisar toda actividad que se realice en el recinto.
                                    </li>
                                    
                                    <li>
                                        El incumplimiento de las obligaciones que se derivan de la condición de usuario podrá llevar consigo la pérdida de tal condición. Dichos incumplimientos podrán ser clasificados como leves y graves, según se detalla en los apartados siguientes:
                                        <ol>
                                            <li>
                                                Incumplimientos leves;
                                                <ol style={{listStyleType : "lower-alpha"}}>
                                                    <li>
                                                        El incumplimiento de algunas de las obligaciones de los usuarios, cuando su consecuencia no dé lugar a la calificación de grave.
                                                    </li>
                                                    <li>
                                                        El trato incorrecto a cualquier usuario o personal.
                                                    </li>
                                                    <li>
                                                        Causar daños leves de forma involuntaria a la instalación o equipamiento.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                Incumplimientos graves;
                                                <ol style={{listStyleType : "lower-alpha"}}>
                                                    <li>
                                                        El incumplimiento reiterado de algunas de las obligaciones de los usuarios.
                                                    </li>
                                                    <li>
                                                        El maltrato de palabra u obra a otros usuarios o empleados de la instalación.
                                                    </li>
                                                    <li>
                                                        Causar daños graves de forma voluntaria a la instalación o equipamiento de la misma.
                                                    </li>
                                                    <li>
                                                        Originar por imprudencia o negligencia accidentes graves a sí mismo u otras personas.
                                                    </li>
                                                    <li>
                                                        El falsear intencionadamente los datos relativos a la identidad, edad, estado de salud o cualquier otra información que impida que BODY BARRE pueda tomar acciones preventivas al respecto.
                                                    </li>
                                                    <li>
                                                        La reincidencia en incumplimientos resueltos como leves.
                                                    </li>
                                                    <li>
                                                        Hurtar, robar o deteriorar material de la instalación o de las pertenencias de otros usuarios, visitantes y/o personal de BODY BARRE.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                CONSECUENCIAS DE LOS INCUMPLIMIENTOS:
                                                <ol style={{listStyleType : "lower-alpha"}}>
                                                    <li>
                                                        Los incumplimientos leves se corregirán con apercibimiento oral o por escrito. BODY BARRE se reserva el derecho de comunicarlo a su personal o registrarlo en las bitácoras que así determine.
                                                    </li>
                                                    <li>
                                                        Los incumplimientos graves se corregirán con la pérdida de la condición de usuario, finalizando el acuerdo de servicio sin oportunidad de reposición, compensación, reembolso o cualquier otra dádiva hacia el usuario.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </p>

                            <p className="text-justify">
                                ANIMALES DE COMPAÑÍA, DE SERVICIO Y/O MASCOTAS<br/><br/>
                                BODY BARRE, en su derecho, reconoce que todas sus instalaciones son pet friendly (instalaciones amigables con los animales de compañía, de servicio y mascotas de sus usuarias) y, por lo tanto, permite el acceso a las alumnas junto con los mismos.<br/>

                                En su caso, aquellas alumnas (usuarias de los servicios de BODY BARRE) que por su propia decisión decidan acudir a las instalaciones de BODY BARRE junto con su mascota o cualquier animal mencionado previamente, serán directamente responsables de cualquier desperfecto, daño o lesión a las instalaciones, mobiliario, equipo, terceros o a sí mismos que estos puedan ocasionar dentro de las instalaciones.<br/>

                                Aquellas alumnas que decidan acudir a las instalaciones de BODY BARRE acompañadas de sus mascotas o cualquier animal mencionado previamente expresan y aseguran, mediante la aceptación de este Reglamento Interno, que sus mascotas o cualquier animal mencionado previamente no son agresivas ni representan algún riesgo o peligro para la sociedad, personal, alumnas o bienes que se encuentren dentro de las instalaciones de BODY BARRE y que cuentan con todas las vacunas correspondientes aplicadas al momento de ingresar a las instalaciones.<br/>

                                Todas las mascotas o cualquier animal mencionado previamente, sin excepción, deberán estar con correa dentro del área asignada previamente por BODY BARRE.<br/>

                                BODY BARRE proveerá los artículos de limpieza necesarios para limpiar el área que la mascota o cualquier animal mencionado previamente pueda ensuciar pero será total obligación del propietario realizar la limpieza del área involucrada, disponiendo de los desechos en los lugares o zonas que BODY BARRE haya determinado para su recolección.<br/>

                                BODY BARRE privilegiará el bienestar de sus usuarios por sobre todo, por lo tanto, y si por cualquier situación la mascota o cualquier animal mencionado previamente genera cualquier tipo de daño físico o emocional a cualquiera de sus usuarios, empleados, colaboradores, instalaciones o bienes, la responsabilidad de cubrir los costos relacionados e incluso posteriores será total y absolutamente responsabilidad del propietario o responsable de la mascota o cualquier animal mencionado previamente, eximiendo a BODY BARRE en su totalidad. BODY BARRE no mediará acuerdos ni soluciones a las partes involucradas salvo cuando se trate específicamente de bienes propiedad de BODY BARRE.<br/>
                            </p>

                            <p className="text-justify">
                                RESPONSABILIDAD<br/><br/>
                                El usuario está consciente de que el ejercicio físico conlleva riesgos y el ejercicio en barra fija no es
                                la excepción, que pueden ser desde lesiones leves, como torceduras, desgarres, etc. Así como
                                también lesiones graves, como pueden ser infartos, parálisis, entre otras varias; por lo que desde
                                este momento el usuario acepta los riesgos y exime de cualquier responsabilidad a BODY
                                BARRE así como también a los socios, accionistas, empleados.
                            </p>
                            <p className="text-justify">
                                Si por alguna razón alguno de nuestros instructores considera, por su experiencia y preparación,
                                que en algún momento no deba de tomar clase alguno de nuestros usuarios por no considerarlo
                                apto en ese momento, sin que medie mayor diálogo el usuario tendrá que acatar dicha
                                disposición.
                            </p>
                            <p className="text-justify">
                                El usuario manifiesta encontrarse en óptimas condiciones físicas al día de hoy, por lo que no tiene
                                contraindicaciones para llevar a cabo esta rutina de ejercicio.
                            </p>
                            <p className="text-justify">
                                El usuario manifiesta y hace constar que, al usar los servicios que BODY BARRE proporciona, tanto en su modalidad presencial como mediante su BODY BARRE APP, acepta en su totalidad este Reglamento Interno junto con todas las disposiciones y definiciones descritas anteriormente.
                            </p>
                            <p className="text-justify">
                                BODY BARRE proporciona a todas sus alumnas una copia electrónica de este documento tanto en su sitio web https://bodybarre.com.mx como en su BODY BARRE APP, disponibles las 24 horas del día, 7 días a la semana y 365 días al año.
                            </p>
                        </Col>
                    </Row>

                </div>
            </div>
        );
    };

}

export default Rules;
