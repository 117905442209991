import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

class Terms extends Component {

    render() {
        return (
            <div className="content">
                <div className="main">
                    <a href="/home">
                        <img
                            alt=""
                            src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb.png"
                            className="file-head"
                        />
                    </a>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={8} lg={8}>
                            <h4 className="text-center">
                                Términos y condiciones de uso.
                            </h4>
                            <p className="text-justify">
                                BODY BARRE MEXICO SA DE CV<br />
                                Términos y condiciones de uso.
                            </p>
                            <p className="text-justify">
                                Los términos y condiciones descritos en el presente (“Condiciones del Usuario”) serán aplicables para el
                                uso de la aplicación BODY BARRE App y/o a través de servicios en línea, la cual está disponible para
                                teléfonos celulares, tablets, computadora, etc., en particular, para utilizar y recibir los Servicios (según
                                dicho término se define más adelante).
                            </p>
                            <p className="text-justify">
                                LE ROGAMOS LEA LAS PRESENTES CONDICIONES DEL USUARIO ATENTAMENTE ANTES DE USAR
                                NUESTROS SERVICIOS BODY BARRE.
                            </p>
                            <p className="text-justify">
                                Estos Términos y Condiciones de Uso constituyen un Contrato de Adhesión entre usted y BODY BARRE
                                MEXICO SA DE CV a continuación (BODY BARRE) y (BODY BARRE App) la cual es una sociedad anónima
                                de capital variable, constituida conforme a las leyes aplicables de México, inscrita en el Registro Público
                                de Comercio del Ciudad de México en el folio mercantil 238401. Registrada con el notario Benito Ivan
                                guerra SILLA, Notario No 7 de la Ciudad de Mexico. El domicilio de BODY BARRE MEXICO para todo lo
                                relativo a este Contrato es el ubicado en Seneca 55, Colonia Polanco, Cp. 11560, Ciudad de México,
                                Mexico.

                            </p>
                            <p className="text-justify">
                                ¿QUÉ SERVICIOS PRESTA BODY BARRE?<br/><br/>
                                BODY BARRE ofrece a los Usuarios servicios que consisten en la realización por éstos de ejercicios de alto
                                rendimiento para mujeres, entre los cuales se encuentran, a manera de ejemplificar pero sin limitar: clases de barre,
                                así como ejercicios físicos mediante el uso de la barra fija en los estudios, horarios y locales de BODY
                                BARRE abiertos o que se abran en México, y en servicios relacionados a dichas clases, incluyendo de
                                asesoría en materia de acondicionamiento físico (“Servicio”).
                            </p>

                            <p className="text-justify">
                                CONFIDENCIALIDAD Y NO COMPETENCIA<br/>
                            </p>
                            <p className="text-justify">
                                Queda prohibido el uso o reproducción total o parcial del material ni de los movimientos aqui demostrado. Tanto de BODY BARRE como de BODY BARRE APP
                            </p>
                            <p className="text-justify">
                                Cualquiera que sea sorprendido acepta y reconoce su responsabilidad en un hurto del material que pertenece a BODY BARRE MEXICO SA DE CV
                            </p>
                            <p className="text-justify">
                                Al aceptar los términos y condiciones, el cliente acepta a no copiar ni usar nada de lo transmitido por este medio para ningún tipo de uso ni personal y/o comercial, pudiendo caer en procesos legales en su contra.
                            </p>

                            <p className="text-justify">
                                ¿CÓMO SE SUSCRIBE UN CONTRATO ENTRE BODY BARRE Y USTED?<br/><br/>
                                Para poder utilizar los Servicios, usted deberá de ingresar a la “BODY BARRE App” y solicitar en el mismo
                                el alta de su cuenta de usuario personal, siguiendo las instrucciones y proporcionando la información en
                                “BODY BARRE App” o Sitio web requerida.
                            </p>

                            <p className="text-justify">
                                Para hacer uso de la “BODY BARRE App”, o del Sitio web o cualquier otro producto o servicio vinculado a
                                estos Términos y Condiciones deberá haber aceptado dichos Términos y Condiciones y nuestro Aviso de
                                Privacidad que se presenta la primera vez que abre “BODY BARRE App” o en el Sitio Web en la parte
                                inferior y cuando realizamos modificaciones sustanciales a dichos Términos y Condiciones o al Aviso de
                                Privacidad. Le suplicamos qué si no acepta los Términos y Condiciones del Servicio y/o el Aviso de
                                Privacidad, no utilice los Servicios; al momento de crear una cuenta con nosotros y de hacer uso de la
                                “BODY BARRE App” o Sitio web y de cualquier otro Servicio de BODY BARRE usted expresamente
                                consiente los Términos y Condiciones del Servicio, así como muestra su conformidad con el Aviso de
                                Privacidad que hemos puesto a su consideración.
                            </p>
                            <p className="text-justify">
                                También deberá aceptar los presentes términos y condiciones mediante la opción electrónica de
                                aceptación de las presentes Condiciones del Usuario que aparece en “BODY BARRE App”, y dicha
                                aceptación se entenderá como el consentimiento expreso entre usted y BODY BARRE para celebrar el
                                presente contrato y cumplir las obligaciones en el mismo establecidas (“Contrato”). Al momento en que
                                usted acepte los presentes Términos y Condiciones del Servicio, BODY BARRE autorizará el alta de su
                                cuenta de usuario personal. Usted se obliga y acepta entregar a BODY BARRE la información personal
                                que BODY BARRE considere en esta fecha o en fecha posterior necesaria o conveniente para poder
                                brindar los Servicios. Sus datos personales serán tratados conforme al Aviso de Privacidad de BODY
                                BARRE, más adelante transcrito, y en cumplimiento a lo dispuesto por la Ley Federal de Protección de
                                Datos Personales en Posesión de los Particulares. Usted acepta proporcionar los datos de su tarjeta de
                                crédito u otro medio de pago electrónico que BODY BARRE acepte como medio de pago por los
                                Servicios, y manifiesta su aprobación parea que BODY BARRE realice los cargos que correspondan por
                                los Servicios contratados. Tras cumplimentar adecuadamente el registro con BODY BARRE; BODY BARRE
                                le proporcionará una cuenta personal a la cual usted podrá acceder a través de “BODY BARRE App” al
                                ingresar con su nombre de usuario y la contraseña que usted elija. Dicha cuenta podrá ser utilizada en el
                                “BODY BARRE App” o en su servicios en línea. En cuanto al sitio web, le sugerimos que lea
                                cuidadosamente los Términos y Condiciones, así como el Aviso de Privacidad que ponemos a su alcance.
                            </p>
                            <p className="text-justify">
                                Usted es el único responsable del manejo de su contraseña y de mantenerla confidencial. Usted acepta y
                                reconoce que las autorizaciones, contrataciones de Servicios y consentimientos que se realicen
                                utilizando su nombre de usuario y contraseña se entenderán hechas por usted en lo personal y como
                                consentimiento expreso de su parte. Por lo anterior le recomendamos no revelar a persona alguna su
                                nombre de usuario y/o contraseña. BODY BARRE no será responsable por el uso inadecuado, o los
                                cargos que se realicen en su tarjeta de crédito u otro medio de pago que haya proporcionado a través
                                del uso de su nombre de usuario y contraseña.
                            </p>
                            <p className="text-justify">
                                ¿CÓMO INSCRIBIRME A LAS CLASES?<br/><br/>
                                Al ingresar BODY BARRE App con su cuenta de usuario y contraseña, BODY BARRE le permitirá apartar
                                un cupo dentro de las clases de BODY BARRE que se encuentren disponibles en el sistema, al momento
                                en que usted indique qué clase desee tomar, usted estará en la lista de asistentes a dicha clase.
                            </p>
                            <p className="text-justify">
                                En el momento en que usted indique y solicite se le aparte un cupo dentro de dicha clase usted estará
                                confirmando su asistencia a la misma y por lo tanto, usted autoriza a BODY BARRE para que realice el
                                cargo automático a la tarjeta de crédito o medio de pago electrónico que usted proporcionó a BODY
                                BARRE al momento de crear su cuenta de usuario, por concepto de la clase a la que usted asista.
                            </p>
                            <p className="text-justify">
                                USO DE BODY BARRE App O EL SERVICIO<br/><br/>
                                Usted deberá garantizar que la información proporcionada a BODY BARRE es completa y veraz. BODY
                                BARRE tendrá derecho, en todo momento, a comprobar la veracidad información facilitada.
                            </p>
                            <p className="text-justify">
                                Es su responsabilidad asegurarse de ingresar a “BODY BARRE App” correcto. BODY BARRE no será
                                responsable si no ingresa a “BODY BARRE App” correcto. BODY BARRE se reserva el derecho a limitar,
                                restringir o incluso prohibir su ingreso al sistema de “BODY BARRE App” con su nombre de usuario y
                                contraseña al “BODY BARRE App”. Asimismo, BODY BARRE se reserva el derecho de limitar, restringir o
                                prohibir en cualquier momento que usted utilice sus Servicios, por cualquier causa y sin necesidad de
                                justificarlo. Al utilizar “BODY BARRE App” o los Servicios, usted acuerda y consiente que:
                            </p>
                            <p className="text-justify">
                                <ol>
                                    <li>
                                        Sólo utilizará los Servicios y “BODY BARRE App” exclusivamente de manera personal, por lo que no
                                        podrá ceder sus espacios en clases u otros Servicios en favor de terceros;
                                    </li>
                                    <li>
                                        No autorizará a otros a usar su cuenta;
                                    </li>
                                    <li>
                                        No utilizará una cuenta que esté sujeta a cualquier derecho de una persona que no sea usted sin la
                                        autorización adecuada;
                                    </li>
                                    <li>
                                        No utilizará los Servicios o “BODY BARRE App” con fines ilícitos, incluyendo, sin limitación, para enviar
                                        o almacenar ningún material ilegal o con fines fraudulentos;
                                    </li>
                                    <li>
                                        No utilizará los Servicios o “BODY BARRE App” para causar molestias, trastornos o inconvenientes;
                                    </li>
                                    <li>
                                        No perjudicará el funcionamiento adecuado de la red;
                                    </li>
                                    <li>
                                        No tratará de dañar los Servicios o “BODY BARRE App” de ningún modo;
                                    </li>
                                    <li>
                                        No copiará ni distribuirá ningún contenido o Servicios de BODY BARRE sin el permiso escrito de BODY
                                        BARRE MEXICO;
                                    </li>
                                    <li>
                                        Guardará de forma segura y confidencial la contraseña de su cuenta y cualquier identificación
                                        facilitada para permitirle acceder al Servicio y a “BODY BARRE App”;
                                    </li>
                                    <li>
                                        Nos facilitará toda las pruebas de identidad que le solicitemos razonablemente de tiempo en
                                        tiempo;
                                    </li>
                                    <li>
                                        Cumplirá con toda la legislación y normas aplicables al usar “BODY BARRE App” o los Servicios.
                                    </li>
                                    <li>
                                        Mantendrá una buena conducta y será respetuoso con las demás personas que utilicen los Servicios.
                                    </li>
                                    <li>
                                        Se obliga a respetar y cumplir los reglamentos que BODY BARRE emita en relación con los Servicios.
                                    </li>
                                </ol>
                            </p>
                            <p className="text-justify">
                                BODY BARRE se reserva el derecho a terminar en cualquier momento y de manera inmediata sin
                                necesidad de declaración judicial los Servicios objeto de las presentes Condiciones del Usuario, en caso
                                de que el Usuario incumpla con cualquiera de las normas anteriores.
                            </p>
                            <p className="text-justify">
                                PAGO<br/><br/>
                                El uso de “BODY BARRE App” será gratuito; sin embargo, una vez suscrito a nuestro Servicio podrá
                                encontrar información sobre las tarifas aplicables para los Servicios en “BODY BARRE App”.
                            </p>
                            <p className="text-justify">
                                BODY BARRE, podrá modificar o actualizar las mismas ocasionalmente, sin necesidad de previo aviso.
                                Será su responsabilidad mantenerse informado sobre las tarifas actuales para disfrutar y contratar los
                                Servicios, por su parte BODY BARRE le cobrará por los Servicios que usted elija.
                            </p>
                            <p className="text-justify">
                                Usted acuerda pagar todos los Servicios que solicite (con independencia de si los utiliza o asiste a las
                                clases), mediante cargo automático a tarjeta de crédito o medio de pago electrónico que usted haya
                                proporcionado a través de “BODY BARRE App”. El costo de los Servicios causa Impuesto al Valor
                                Agregado. En todo caso, usted será responsable del pago puntual de todos los Servicios que solicite.
                            </p>
                            <p className="text-justify">
                                Los pagos y cargos realizados no son reembolsables. BODY BARRE podrá utilizar procesadores de pagos
                                de terceros (“Procesador de Pago”) para vincular su tarjeta de crédito o el medio de pago electrónico
                                que nos proporcione a su nombre de usuario y contraseña en “BODY BARRE App”. El procesamiento de
                                pagos, con respecto al uso que haga de los Servicios estará sujeto a las condiciones y políticas de
                                privacidad del Procesador del Pago y el emisor de su tarjeta de crédito además de a estos Términos y
                                Condiciones del Servicio. BODY BARRE no será responsable de ningún error del Procesador de Pago. En
                                relación con el uso de “BODY BARRE App” y los Servicios, BODY BARRE obtendrá determinados datos de
                                la transacción, que BODY BARRE utilizará únicamente de conformidad al Aviso de Privacidad de BODY
                                BARRE MEXICO.
                            </p>
                            <p className="text-justify">
                                INDEMNIZACIÓN<br/><br/>
                                Al aceptar estos términos y condiciones del usuario y utilizar su nombre de usuario y contraseña en
                                “BODY BARRE App”, usted acuerda y acepta que libera a BODY BARRE de toda y cualquier
                                responsabilidad, y se obliga a indemnizar y mantener indemne a BODY BARRE, sus filiales, licenciatarios
                                y todos sus directivos, directores, instructores, otros usuarios, trabajadores, representantes y asesores
                                por cualesquiera reclamaciones, costos, daños, pérdidas, responsabilidades y gastos (incluyendo
                                honorarios y gastos de abogados) derivados de o en relación con:
                            </p>
                            <p className="text-justify">
                                <ul>
                                    <li>
                                        Violación o incumplimiento de cualquier condición de estos Términos y Condiciones del Servicio o
                                        cualquier ley o reglamento aplicable, se haga o no referencia al mismo en el presente;
                                    </li>
                                    <li>
                                        Violación de cualquier derecho de cualquier tercero, o
                                    </li>
                                    <li>
                                        Uso o uso incorrecto de “BODY BARRE App” o los Servicios.
                                    </li>
                                </ul>
                            </p>
                            <p className="text-justify">
                                RESPONSABILIDAD<br/><br/>
                                La información, recomendaciones u otros servicios prestados en o a través de “BODY BARRE App” y o
                                por el uso de los Servicios, son sólo información general y no constituyen un aviso. BODY BARRE se
                                ocupará de mantener “BODY BARRE App” y sus contenidos de forma razonablemente correcta y
                                actualizada, pero no podrá garantizar que los contenidos de la “BODY BARRE App” carezcan de errores,
                                defectos, malware y virus y que “BODY BARRE App” sean correctos, estén actualizados y sean precisos.
                                BODY BARRE no será responsable por ningún daño derivado del uso del (o incapacidad de usar) “BODY
                                BARRE App” incluyendo los daños causados por malware, virus o cualquier información incorrecta o
                                incompleta de la información de “BODY BARRE App”, salvo que este daño se derive de cualquier
                                conducta dolosa o negligencia grave por parte de BODY BARRE.
                            </p>
                            <p className="text-justify">
                                Asimismo, BODY BARRE no será responsable por los daños derivados del uso de (o incapacidad de usar)
                                los medios de comunicación electrónicos con “BODY BARRE App”, incluyendo sin limitación daños
                                derivados del fallo o retraso en la entrega de comunicaciones electrónicas, intercepción o manipulación
                                de comunicaciones electrónicas por terceros o por programas informáticos usados para comunicaciones
                                electrónicas y transmisión de virus.
                            </p>
                            <p className="text-justify">
                                VIGENCIA Y FINALIZACIÓN DEL CONTRATO.<br/><br/>
                                Se suscribe el presente Contrato entre BODY BARRE y usted por un periodo indefinido y durante el
                                tiempo que usted utilice y acceda a “BODY BARRE App”. Usted y BODY BARRE tendrán derecho a
                                finalizar el Contrato en todo momento, siempre y cuando usted mediante escrito libre solicite a BODY
                                BARRE deshabilite su nombre de usuario de “BODY BARRE App”. BODY BARRE tendrá derecho a
                                terminar el Contrato en todo momento y con efecto inmediato (deshabilitando el uso del Servicio) si
                                usted: • Viola o incumple cualquier condición de las presentes Condiciones del Usuario, o • A
                                consideración de BODY BARRE, hace un uso indebido de “BODY BARRE App” o los Servicios. BODY BARRE
                                no estará obligado a dar un aviso previo de la terminación del Contrato. Después de su terminación
                                BODY BARRE avisará de ello con arreglo a las presentes Condiciones del Usuario.
                            </p>
                            <p className="text-justify">
                                MODIFICACIÓN DE LOS SERVICIOS Y LAS CONDICIONES DEL USUARIO<br/><br/>
                                BODY BARRE se reserva el derecho, a su discreción, de modificar o sustituir cualquiera de los presentes
                                Términos y Condiciones del Servicio, o cambiar, suspender o interrumpir los Servicios o el acceso a
                                “BODY BARRE App” (incluyendo, sin limitación, la disponibilidad de cualquier característica, base de
                                datos o contenido) en cualquier momento mediante la publicación de un aviso en “BODY BARRE App” o
                                a través de correo electrónico.
                            </p>
                            <p className="text-justify">
                                NOTIFICACIONES<br/><br/>
                                BODY BARRE podrá emitir notificaciones o avisos a usted a través de un aviso general en la “BODY
                                BARRE App” o por correo electrónico a la dirección registrada en la información de la cuenta de BODY
                                BARRE, o mediante una comunicación escrita enviada por correo ordinario a la dirección registrada en la
                                información de la cuenta de BODY BARRE.
                            </p>
                            <p className="text-justify">
                                Asimismo, podrá recibir notificaciones constantes sobre actualizaciones de los servicios contratados
                                siempre y cuando usted acepte lo anterior cuando la aplicación le sugiere la posibilidad.
                            </p>

                            <p>
                                CANCELACI&Oacute;N DE RESERVACIONES E INASISTENCIAS Y PENALIZACI&Oacute;N<br/><br/>
                                El usuario tendr&aacute; siempre la posibilidad de cancelar la clase previamente reservada con una anticipaci&oacute;n 
                                de 12 (doce) horas respecto a la fecha y hora de inicio de la misma, siguiendo el proceso de Cancelaci&Oacute;n 
                                de Reservaci&Oacute;n integrado en la App BodyBarre. De forma autom&aacute;tica, el sistema abonar&aacute; nuevamente 1 (una) 
                                clase por cada cancelaci&oacute;n que cumpla las caracter&iacute;sticas descritas anteriormente a la cuenta del usuario 
                                sin necesidad de que éste deba realizar alg&uacute;n proceso adicional.
                            </p>
                            <p>
                                En caso de que el momento de cancelaci&oacute;n sea menor a 12 (doce) horas respecto a la fecha y hora de inicio de la clase 
                                previamente reservada por el usuario, este último acepta que BodyBarre App considere esto como una excepci&oacute;n a la regla 
                                y, por lo tanto, la clase cancelada NO ser&aacute; abonada o devuelta a la cuenta del usuario ni bonificada de cualquier otra forma.
                            </p>
                            <p>
                                En caso de no presentarse al estudio en el horario de la clase reservada por el usuario, BodyBarre SA de CV se reserva el derecho 
                                de penalizarle mediante el descuento autom&aacute;tico e inmediato de 1 (una) clase adicional que ser&aacute; disminuida del total de clases 
                                disponibles que el usuario disponga a ese momento.
                            </p>
                            <p>
                                BodyBarre SA de CV ofrece y comunica a cada uno de sus usuarios diferentes medios de contacto entre este y la empresa para atender 
                                cualquier situaci&oacute;n de informaci&oacute;n, atención a clientes, seguimiento y notificaciones de distinta &iacute;ndole. Dichos canales oficiales 
                                pueden ser utilizados por el usuario para notificar a BodyBarre SA de CV cualquier situación que le impida, limite o no permita su 
                                asistencia a la clase que ha reservado previamente desde la App BodyBarre. Esto con la finalidad de proveer al usuario con distintos 
                                canales de comunicaci&oacute;n para contactar a BodyBarre y notificar alguna situación fortuita que, tras la entrega de los documentos o pruebas 
                                que efectivamente acrediten que el usuario no pudo asistir a la clase previamente reservada, ser&aacute;n tomados en consideraci&oacute;n para determinar 
                                si la clase le ser&aacute; devuelta o no al usuario.
                            </p>
                            <p>
                                La decisi&oacute;n tomada por el grupo de administraci&oacute;n de BodyBarre SA de CV, ser&aacute; comunicada al usuario por el mismo canal de comunicaci&oacute;n que se 
                                haya usado inicialmente o cualquier otro con el que BodyBarre cuente al momento del reporte, indicando al usuario el resultado de la revisi&oacute;n 
                                de su caso donde se han considerado los elementos y consideraciones de ambas partes. Esta decisión ser&aacute; de car&aacute;cter irrevocable y deber&aacute; ser aceptada por el usuario.
                            </p>
                            <p>
                                En situaciones en cuyo caso el usuario no pueda notificar con antelación su inasistencia a BodyBarre SA de CV, ya sea en casos m&eacute;dicos, incidentes fortuitos o 
                                cualquier otra situaci&oacute;n que impida al usuario acudir a la clase previamente reservada, el usuario acepta que su caso ser&aacute; revisado y aceptar&aacute; incondicionalmente 
                                la resoluci&oacute;n que el grupo de administraci&oacute;n de BodyBarre SA de CV determine.
                            </p>
                            <p className="text-justify">
                                JURISDICCIÓN Y LEY APLICABLE<br/><br/>
                                Las partes están de acuerdo en que el presente Contrato se regirá por las leyes aplicables vigentes en
                                México, Ciudad de México. Para la interpretación y cumplimiento del Contrato, las partes se someten a
                                la jurisdicción de los tribunales competentes en México, Ciudad de México, renunciando expresamente
                                a cualquier otro fuero que pudiera corresponderles por razón de sus domicilios presentes o futuros o
                                por cualquier otra causa.
                            </p>
                            <p className="text-justify">
                                ACEPTACIÓN DE RIESGO, RENUNCIA Y LIBERACIÓN DE RESPONSABILIDAD<br/><br/>
                                Mediante la inscripción a BODY BARRE y/o asistiendo a clases, eventos, actividades y otros programas y
                                el uso de las instalaciones y el equipo (“Clases” y/o “Instalaciones”) de BODY BARRE, por la presente
                                reconozco que existen ciertos riesgos y peligros inherentes al uso y práctica de cualquier ejercicio físico
                                y en específico pero sin limita; a la práctica y uso de barras fijas, durante las Clases que se imparten en
                                BODY BARRE. También reconozco que los riesgos específicos varían de una actividad a otra, mismos que
                                podrían ser (a) lesiones menores como: (1) rasguños; y (2) golpes y torceduras; (b) lesiones mayores
                                como (1) lesiones en las articulaciones o la espalda; (2) ataques cardíacos; y (3) contusiones; y (c)
                                lesiones graves, incluyendo parálisis, y muerte por lo que expresamente reconozco y acepto que dichos
                                riesgos no pueden ser eliminados por BODY BARRE y que dependen de usted al realizarlos, por lo cual
                                recomendamos ampliamente que cuando realice actividad física sea conforme a su capacidad para
                                llevarla a cabo, y estando en perfectas condiciones físicas para practicar las actividades que BODY BARRE
                                pone a su alcance. He leído y entendido completamente el reglamento interno de BODY BARRE, mismos
                                que se publican en “BODY BARRE App” y el cual se encuentra a disposición de todos los usuarios en cada
                                sucursal BODY BARRE de manera visible. Me comprometo a cumplir con todos los Términos y
                                Condiciones establecidos en dichos documentos, así como las instrucciones que de tiempo en tiempo el
                                personal de BODY BARRE me proporcione durante el desarrollo de las Clases, o en su caso, con las
                                instrucciones que BODY BARRE ponga en el establecimiento donde se lleven a cabo las Clases. Si en
                                cualquier momento, el personal de BODY BARRE me sugiere y me indica que no podré llevar a cabo
                                cualesquiera de las Clases que BODY BARRE imparte acataré dicha instrucción. Lo anterior, basado en la
                                opinión del personal de BODY BARRE quienes reconozco están debidamente capacitados para emitir
                                dicha opinión, por lo que entiendo y acepto que dicha opinión siempre será en mi beneficio y en
                                cuidado de mi salud. En relación con lo anterior, en caso que BODY BARRE me permita tomar las Clases
                                (i) asumo plena responsabilidad por cualquier y todas las lesiones o daños que sufra (incluso muerte)
                                durante o derivado de las Clases, (ii) libero a BODY BARRE y sus subsidiarias, y cada uno de sus socios,
                                accionistas, consejeros, funcionarios, directores, empleados, representantes y agentes, y cada uno de
                                sus respectivos sucesores y cesionarios de cualquier y toda responsabilidad, reclamaciones, acciones,
                                demandas, procedimientos, costos, gastos, daños y pasivos; y (iii) manifiesto que al día de la presente
                                (a) no tengo ningún impedimento médico o condición física que me impida tomar las clases o usar
                                correctamente los aparatos mediante los cual se llevan a cabo las Clases; (b) no tengo una condición
                                física o mental que me ponga peligro médico y físico; y (c) no tengo instrucciones médicas que me
                                limiten o restrinjan realizar cualquier tipo de actividad física.
                            </p>
                            <p className="text-justify">
                                Reconozco que si tengo alguna discapacidad o enfermedad crónica, estoy en riesgo al hacer uso de las
                                instalaciones y acudir a las Clases, y que no debería de participar en cualquiera de las Clases.
                            </p>
                            <p className="text-justify">
                                He leído esta declaratoria de aceptación de riesgo, renuncia y liberación de responsabilidad, y deslindo
                                de toda responsabilidad, obligándome a sacar en paz y a salvo a BODY BARRE y/o a todas sus
                                subsidiaras, y a cada uno de sus socios, accionistas, consejeros, funcionarios, directores, empleados
                                representantes uy agentes respecto de toda acción, demanda, responsabilidad de carácter civil o penal
                                derivado de cualquier contingencia, accidente, daño, o cualquier tipo de lesión, enfermedad, fracturas,
                                incapacidad parcial o permanente y/o la muerte que pudiera sufrir el que suscribe por el uso de las
                                Instalaciones BODY BARRE y/o por las Clases que tome. Reconozco que estoy firmando el presente de
                                manera libre y voluntariamente y que la vigencia de esta renuncia es indefinida por lo que continuará
                                valida y vigente durante el tiempo que acuda a las Instalaciones y/o toma clases de BODY BARRE
                            </p>
                            <p className="text-justify">
                                ACEPTACIÓN Y CONSENTIMIENTO DE USO DE IMAGEN BODY BARRE + CLASES EN VIVO
                            </p>
                            <p className="text-justify">
                                <ol>
                                    <li>
                                        Usted reconoce y autoriza que BODY BARRE pueda hacer uso de cualquier medio donde aparezca su
                                        imagen y/o voz, que haya sido creada o tomada durante cualquiera de las clases de BODY BARRE, en el
                                        entendido de que las impartidas en el estudio podrán ser video- grabadas para fines de “BODY BARRE
                                        App” clases en vivo diariamente, incluyendo, la fijación, incorporación y/o sincronización de los videos o
                                        sus medios de promoción, por cualquier medio y bajo cualquier forma, y la explotación de manera no
                                        independiente y en consonancia con el Proyecto, en todo el mundo, hasta el paso de los derechos a
                                        dominio público, a través de cualquier formato y modalidad de explotación.
                                    </li>
                                    <li>
                                        Usted entiende y acepta que la(s) clase(s) de BODY BARRE que compre podrán ser video-grabadas
                                        para fines de reproducir en línea a través de “BODY BARRE App” La grabación será enfocada al instructor
                                        de BODY BARRE; sin embargo, su imagen podrá ser captada como parte de la clase que el instructor esté
                                        impartiendo.
                                    </li>
                                    <li>
                                        Usted renuncia a cualquier derecho que pudiera corresponder en relación con inspección o
                                        aprobación de la versión final de las imágenes, copias para publicidad, textos, o cualquier otro elemento
                                        impreso o electrónico que pudiera usarse o las imágenes que pudieran ser incluidas.
                                    </li>
                                    <li>
                                        Al momento de comprar la clase y asistir a la misma usted libera a BODY BARRE, así como a sus
                                        representantes, empleados, o cualquier persona, empresa o empresas que estén actuando en su
                                        nombre, incluyendo cualquier firma, despacho, o empresa encargada de la publicidad o distribución del
                                        producto final, todo o en parte, de cualquier responsabilidad que resulte de cualquier distorsión, imagen
                                        borrosa, o alteración, ilusiones ópticas o cualquier otra que pudiera resultar del proceso de
                                        reproducción del producto final, su publicación o distribución, aun cuando dicha situación pudiera
                                        representar una disociación, escándalo, reprobación, engaño o indignación.
                                    </li>
                                </ol>
                            </p>
                            <p className="text-justify">
                                VALORES Y BIENES PERSONALES:<br/><br/>
                                BODY BARRE no será responsable por la pérdida, robo, o daños a cualquier objeto, incluyendo artículos
                                dejados en casilleros, baños, estudios, o cualquier otro lugar en las instalaciones. Asimismo, acepto y
                                reconozco que BODY BARRE se reserva el derecho a denegar el acceso a cualquier persona que BODY
                                BARRE considere que esté actuando de manera inadecuada o que pongan en riesgo su salud o la salud
                                de los clientes.
                            </p>
                        </Col>
                    </Row>

                </div>
            </div>
        );
    };

}

export default Terms;
